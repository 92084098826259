<template>
  <div>
    <v-text-field
      :rules="useUsPlans ? [] : [ mustHaveDiff ]"
      class="d-none"
    />
    <template v-if="!useUsPlans">
      <template
        v-if="showProvinceSelector"
      >
        <p
          class="text-body-1 grey--text text--darken-4 px-0"
        >
          Select your province:
        </p>
        <p class="text-caption grey--text text--darken-1 px-0 mt-n4">
          Your province will affect your publications
        </p>
        <v-select
          ref="subscriptionProvince"
          v-model="selectedProvince"
          data-qa="addon-selector-province-select-field"
          name="province"
          label="Province"
          item-value="id"
          :items="provinces"
          :menu-props="{ bottom: true, offsetY: true }"
          :rules="provinceRules"
          return-object
          outlined
        />
      </template>
      <template v-if="!onlyAddons">
        <p
          v-if="!enforceDefault"
          class="text-body-1 grey--text text--darken-4 px-0"
        >
          Choose a publication source
        </p>
        <p
          v-else
          class="text-body-1 grey--text text--darken-4 px-0"
        >
          Your publication source
        </p>
        <p class="text-caption grey--text text--darken-1 px-0 mt-n4">
          This source is included in your plan after your trial ends.
        </p>
        <v-select
          v-model="defaultPub"
          :items="publications"
          :rules="[ requiredValueRule('Publication Source') ]"
          label="Publication Source"
          item-text="name"
          item-value="id"
          outlined
          :readonly="enforceDefault"
        >
          <template
            v-if="enforceDefault"
            #append
          >
            <div />
          </template>
        </v-select>
      </template>
      <template v-if="!onlyAddons || showExpandAnalysis">
        <p class="text-body-1 grey--text text--darken-4 px-0">
          Expand your analysis
        </p>
        <p class="text-caption grey--text text--darken-1 px-0 mt-n4">
          You can add more analytical teams to your GrainFox plan.
        </p>
      </template>
    </template>
    <template v-if="useUsPlans">
      <p
        class="text-body-1 px-0 font-weight-medium"
      >
        Your Plan
      </p>
      <p class="text-caption grey--text text--darken-3 px-0 mt-n4">
        {{ humanizePlan(planId) }}
      </p>
    </template>
    <template v-for="(addon, index) in addonEntries">
      <v-row
        :key="index"
        class="flex-nowrap px-3 justify-space-between align-center my-4"
      >
        <span
          class="text-body-1"
          :class="{'font-weight-bold' : addon.added, 'grey--text' : !addon.clickable}"
        >
          {{ addon.text }}
        </span>
        <div class="d-flex align-center g-4">
          <template v-if="showAddonPrices">
            <div>
              <animated-number
                class="text-right text-body-1 grey--text text--darken-4"
                prefix="$"
                :number="addon.price"
              />
              <span class="text-caption">/{{ frequency }}</span>
              <span
                v-if="showCouponDisclaimer"
                class="success--text text-caption"
              >*</span>
            </div>
          </template>
          <v-btn
            slot="append"
            icon
            class="button grey"
            :disabled="!addon.clickable"
            :class="{ 'primary': !addon.added, 'lighten-3': addon.added }"
            :color="!addon.added && addon.clickable ? 'white' : 'grey--text'"
            @click="toggle(addon.id)"
          >
            <v-icon>
              {{ addon.added ? 'mdi-minus' : 'mdi-plus' }}
            </v-icon>
          </v-btn>
        </div>
      </v-row>
      <p
        :key="`${index}-description`"
        class="text-caption grey--text text--darken-1"
      >
        {{ addon.description }}
      </p>
    </template>
    <v-divider class="my-4" />
    <v-row class="flex-nowrap px-3 justify-space-between my-2">
      <span class="font-weight-medium primary-grey--text text-body-1 px-0">
        Total
      </span>
      <span
        class="text-right text-h6 primary--text font-weight-regular pl-0"
        :class="{'pr-13': showAddonPrices}"
      >
        <animated-number
          prefix="$"
          :number="total"
        />
        <span class="text-caption">/{{ frequency }}</span>
        <span
          v-if="showCouponDisclaimer"
          class="success--text text-caption"
        >*</span>
      </span>
    </v-row>
    <v-row
      v-if="showCouponDisclaimer"
      class="flex-nowrap px-3 my-2 success--text"
    >
      <span class="text-caption">*</span>
      <span class="">Any coupons or discounts will be applied at checkout.</span>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import AnimatedNumber from '@/components/generic/AnimatedNumber.vue';
import {
  DEPUTTER_ADDON,
  FARMLINK_ADDON,
  WEEKLY_RESEARCH_ADDON,
  SMART_PLAN,
  ANNUAL,
  MONTHLY,
  USD,
  CAD,
} from './data/constants';
import { requiredValueRule } from '../../helpers/rules';
import { formatCurrency } from '../../helpers/formatting';
import { humanizePlan } from './data/helpers';

export default {
  name: 'AddonSelector',
  components: {
    AnimatedNumber,
  },
  props: {
    annualBilling: {
      type: Boolean,
      default: true,
    },
    showCouponDisclaimer: {
      type: Boolean,
      default: true,
    },
    onlyAddons: {
      type: Boolean,
      default: true,
    },
    showExpandAnalysis: {
      type: Boolean,
      default: false,
    },
    showAddonPrices: {
      type: Boolean,
      default: false,
    },
    defaultPublication: {
      type: String,
      default: null,
    },
    enforceDefault: {
      type: Boolean,
      default: false,
    },
    allowEditAdded: {
      type: Boolean,
      default: true,
    },
    includePlanPrice: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      default: () => {},
    },
    planId: {
      type: String,
      default: SMART_PLAN,
    },
    useUsPlans: {
      type: Boolean,
      default: false,
    },
    priceOverride: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      publications: [{
        name: 'FarmLink Publications',
        id: 'FarmLink-Publications',
      }, {
        name: 'DePutter Publications',
        id: 'DePutter-Publications',
      }],
      defaultPub: this.defaultPublication,
      addons: this.useUsPlans ? {
        [WEEKLY_RESEARCH_ADDON]: true,
      } : {
        [DEPUTTER_ADDON]: true,
        [FARMLINK_ADDON]: true,
        ...this.value,
      },
      provinceRules: [requiredValueRule('Province')],
      selectedProvince: this.province,
    };
  },
  computed: {
    ...mapGetters('chargebee', ['getPrice']),
    ...mapState('subscription', ['province']),
    ...mapState('shared', ['provinces']),
    ...mapState('launchDarkly', ['flags']),

    shouldAddonsNotBeAutoSelected() {
      return this.flags['addon-selector-opt-out'];
    },

    deputter() {
      return DEPUTTER_ADDON;
    },
    farmlink() {
      return FARMLINK_ADDON;
    },

    currency() {
      return this.useUsPlans ? USD : CAD;
    },

    total() {
      let price = this.selectedAddons.map((addonId) => {
        if (this.defaultPub === addonId) {
          return 0;
        }
        return this.getPrice({
          id: addonId,
          frequency: this.annualBilling ? ANNUAL : MONTHLY,
          currency: this.currency,
        }, {
          normalize: MONTHLY,
        });
      }).reduce((a, b) => a + b, 0);

      if (this.includePlanPrice) {
        if (this.priceOverride) {
          return (this.annualBilling ? price * 12 : price) + this.priceOverride;
        }
        price += this.getPrice({
          id: this.planId,
          frequency: this.annualBilling ? ANNUAL : MONTHLY,
          currency: this.currency,
        }, {
          normalize: MONTHLY,
        });
      }
      if (this.annualBilling) {
        price *= 12;
      }
      return price;
    },
    frequency() {
      return this.annualBilling ? 'year' : 'month';
    },
    isOnlyOneActive() {
      return this.selectedAddons.length <= 1;
    },
    selectedAddons() {
      if (this.showProvinceSelector && !this.selectedProvince) {
        return [];
      }
      return Object.entries(this.addons).filter(([, value]) => value).map(([key]) => key);
    },
    selectedAddonObjects() {
      return this.selectedAddons.map((addon) => ({
        // Helpful info
        name: addon,
        frequency: this.annualBilling ? ANNUAL : MONTHLY,
        currency: this.currency,
        // Chargebee stuff
        item_price_id: `${addon}-${this.currency}-${this.annualBilling ? ANNUAL : MONTHLY}`,
        unit_price: this.getPrice({
          id: addon,
          frequency: this.annualBilling ? ANNUAL : MONTHLY,
          currency: this.currency,
        }, {
          normalize: false,
          type: 'cents',
        }),
      }));
    },
    diffAddons() {
      if (!this.value) {
        return this.selectedAddons;
      }

      return this.selectedAddons.filter((x) => !(x in this.value) || !this.value[x]);
    },
    mustHaveDiff() {
      return this.diffAddons.length > 0;
    },
    addonEntries() {
      return this.useUsPlans ? [
        {
          id: WEEKLY_RESEARCH_ADDON,
          added: this.isAdded(WEEKLY_RESEARCH_ADDON),
          clickable: this.isClickable(WEEKLY_RESEARCH_ADDON),
          text: 'Add Weekly Research',
          description: 'The Weekly Research provided by AgResource is now available on GrainFox. The Weekly Research provides readers with information on long term fundamental trends and worldwide analysis. Using in-depth statistical research, and a detailed global outlook, the Weekly Newsletter gives an overall view of current agriculture.',
          price: this.addonPrice(WEEKLY_RESEARCH_ADDON),
        },
      ] : [{
        id: FARMLINK_ADDON,
        added: this.isAdded(FARMLINK_ADDON),
        clickable: this.isClickable(FARMLINK_ADDON),
        text: 'FarmLink',
        price: this.addonPrice(FARMLINK_ADDON),
      }, {
        id: DEPUTTER_ADDON,
        added: this.isAdded(DEPUTTER_ADDON),
        clickable: this.isClickable(DEPUTTER_ADDON),
        text: 'DePutter',
        price: this.addonPrice(DEPUTTER_ADDON),
      }];
    },
    showProvinceSelector() {
      return Object.keys(this.province).length === 0;
    },
  },

  watch: {
    defaultPublication(val) {
      if (val) {
        this.addons[val] = true;
        this.updateAddons();
      }
    },
    defaultPub(val) {
      if (val) {
        this.addons[val] = true;
        this.updateAddons();
      }
    },
    value: {
      deep: true,
      handler(val) {
        this.addons = { ...this.addons, ...val };
      },
    },
    selectedProvince(val) {
      if (val) {
        this.defaultPub = val.group === 'western' ? FARMLINK_ADDON : DEPUTTER_ADDON;
      }
    },
  },
  async mounted() {
    if (this.showProvinceSelector && this.provinces.length === 0) {
      await this.fetchCommoditiesAndProvinces();
    }
  },
  created() {
    if (!this.showProvinceSelector) {
      this.updateAddons();
    }
  },
  methods: {
    ...mapActions('shared', ['fetchCommoditiesAndProvinces']),
    requiredValueRule,
    formatCurrency,
    humanizePlan,
    toggle(addon) {
      this.addons[addon] = !this.addons[addon];
    },
    isAdded(addon) {
      return Boolean(this.addons[addon]);
    },
    isClickable(addon) {
      return !(
        ((this.isOnlyOneActive && this.isAdded(addon))
        || this.defaultPub === addon
        || (this.defaultPub === null && !this.onlyAddons)
        || (!this.allowEditAdded && this.value[addon]))
        && !this.useUsPlans
      );
    },
    addonPrice(addon) {
      if ((addon === this.defaultPub || (this.isOnlyOneActive && this.isAdded(addon))) && !this.useUsPlans) {
        return 0;
      }
      return this.getPrice({ id: addon, frequency: this.annualBilling ? ANNUAL : MONTHLY, currency: this.currency });
    },
    updateAddons() {
      if (this.shouldAddonsNotBeAutoSelected) {
        Object.keys(this.addons).forEach((key) => {
          if (key !== this.defaultPub) {
            this.addons[key] = false;
          }
        });
      }
    },
  },
};
</script>
