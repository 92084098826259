<template>
  <v-navigation-drawer
    v-if="isAuthenticated"
    v-model="isOpen"
    v-click-outside="handleClickOutside"
    app
    temporary
    touchless
    floating
    disable-resize-watcher
    class="grainfox-navigation grey lighten-4"
    :class="{'elevation-3': !isCollapsed}"
    :style="positionStyle"
    :width="320"
    :stateless="!$isMobile"
    :mini-variant="miniVariant"
    :hide-overlay="hideOverlay"
    :mini-variant-width="80"
    :mobile-breakpoint="0"
    @input="handleInput"
  >
    <v-btn
      v-if="!$isMobile"
      data-qa="app-navigation-toggle-button"
      class="grainfox-navigation__toggle"
      color="primary"
      :width="24"
      :height="24"
      fab
      @click="isCollapsed = !isCollapsed"
    >
      <v-icon small>
        {{ arrowIcon }}
      </v-icon>
    </v-btn>
    <div class="grainfox-navigation__content g-scroll--hide d-flex flex-column">
      <!-- Logo -->
      <div
        :style="logoHeightStyle"
        class="d-flex align-center flex-shrink-0 mx-4 my-1 pl-1 p-rel"
      >
        <GrainFoxNavLogo
          :width="logoWidth"
          class="grainfox-navigation__logo c-pointer"
          @click.native="isCollapsed = !isCollapsed"
        />
        <v-btn
          v-if="$isMobile"
          color="primary"
          class="ml-auto"
          text
          icon
          large
          @click="toggleNavDrawerState"
        >
          <v-icon large>
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <!-- Content -->
      <v-list
        data-qa="app-navigation-list"
        nav
        expand
        class="overflow-x-hidden overflow-y-auto pa-4"
      >
        <PartnershipLogo
          v-if="showCampaignIcon && showCobrandingLogo"
          :is-collapsed="!showFullLogo"
          :logo-expanded="campaignLogoExpanded"
          :logo-collapsed="campaignLogoCollapsed"
          :alt="fetchedCampaign.campaign_name"
          class="mb-2"
        />
        <PartnershipLogo
          v-if="showIntegrationLogo"
          :is-collapsed="!showFullLogo"
          :logo-collapsed="integrationLogo"
          :logo-expanded="integrationLogo"
          :alt="integrationName"
          class="mb-2"
        />
        <v-list-item
          v-if="$isMobile"
          class="mx-2"
          :class="$isMobile ? 'd-flex': 'd-none'"
        >
          <AccountMenu full-width />
        </v-list-item>
        <v-list-item
          v-if="$isMobile"
          class="px-0"
          :class="$isMobile ? 'd-flex': 'd-none'"
        >
          <NotificationsDropdown full-width />
        </v-list-item>
        <v-divider
          v-if="showCampaignIcon || $isMobile || showIntegrationLogo"
          class="mt-6 mb-4"
        />
        <navigation-links
          :items="navDrawerItems"
          @expand="handleGroupExpand"
        />
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import AccountMenu from '@/components/account/AccountMenu.vue';
import NavigationLinks from './NavigationLinks.vue';
import NotificationsDropdown from './NotificationsDropdown.vue';
import HelpDocument from './HelpDocument.vue';
import PartnershipLogo from './PartnershipLogo.vue';
import GrainFoxNavLogo from './GrainFoxNavLogo.vue';

export default {
  name: 'AppNavigation',

  components: {
    AccountMenu,
    NavigationLinks,
    NotificationsDropdown,
    HelpDocument,
    PartnershipLogo,
    GrainFoxNavLogo,
  },

  data() {
    return {
      isCollapsed: true,
    };
  },

  computed: {
    ...mapState('app', ['headerHeightDesktop', 'navDrawerWidth', 'navDrawerItems']),
    ...mapState('marketingModule', ['fetchedCampaign', 'campaignCobranding']),
    ...mapGetters('user', ['isAuthenticated']),
    ...mapState('subscription', ['gmaIntegration']),

    campaignLogoCollapsed() {
      if (!this.campaignCobranding?.length) {
        return '';
      }
      const logo = this.campaignCobranding.find((cobranding) => cobranding.section === 'navigation:collapsed');
      return logo?.logo ?? '';
    },

    campaignLogoExpanded() {
      if (!this.campaignCobranding?.length) {
        return '';
      }
      const logo = this.campaignCobranding.find((cobranding) => cobranding.section === 'navigation:expanded');
      return logo?.logo ?? '';
    },

    showCobrandingLogo() {
      return this.showFullLogo ? !!this.campaignLogoExpanded : !!this.campaignLogoCollapsed;
    },

    integrationLogo() {
      if (!this.gmaIntegration) {
        return '';
      }
      if (this.showFullLogo) {
        return this.gmaIntegration.logos.wide ?? this.gmaIntegration.logos.square;
      }
      return this.gmaIntegration.logos.square;
    },

    integrationName() {
      return this.gmaIntegration?.name;
    },

    showIntegrationLogo() {
      return !!this.gmaIntegration && !this.showCampaignIcon;
    },

    isNavDrawerOpen: {
      get() {
        return this.$store.state.app.isNavDrawerOpen;
      },
      set(value) {
        this.$store.commit('app/setNavDrawerState', value);
      },
    },

    isOpen: {
      get() {
        return this.$isMobile ? this.isNavDrawerOpen : true;
      },
      set() {},
    },

    hideOverlay() {
      return !this.$isMobile || !this.isOpen;
    },

    miniVariant() {
      if (this.$isMobile) return false;
      return this.isCollapsed;
    },

    arrowIcon() {
      return this.isCollapsed ? 'mdi-menu-right' : 'mdi-menu-left';
    },

    showFullLogo() {
      return !this.miniVariant;
    },

    logoHeightStyle() {
      return `height:${this.headerHeightDesktop}px;`;
    },

    logoWidth() {
      return this.showFullLogo ? 200 : 48;
    },

    positionStyle() {
      return `top:${this.$vuetify.application.bar}px!important;max-height:calc(100% - ${this.$vuetify.application.bar}px)`;
    },

    showCampaignIcon() {
      return !!this.fetchedCampaign && !!this.campaignCobranding;
    },
  },

  watch: {
    $isMobile: {
      handler(newValue) {
        if (newValue === false) {
          this.isNavDrawerOpen = false;
        }
      },
    },
  },

  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'app/setNavDrawerState') {
        this.isCollapsed = !mutation.payload;
      }
    });
  },

  methods: {
    ...mapMutations('app', ['toggleNavDrawerState']),

    handleClickOutside() {
      if (!this.isCollapsed) {
        this.isCollapsed = true;
      }
    },

    handleInput(value) {
      if (this.isNavDrawerOpen && value === false) {
        this.$store.commit('app/setNavDrawerState', false);
      }
    },

    async handleGroupExpand(group) {
      if (this.miniVariant) {
        this.isCollapsed = false;
        await this.$nextTick();
        this.$set(group, 'isActive', true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/colors/colors";

.grainfox-navigation {
  overflow: visible !important;

  &::v-deep.v-navigation-drawer {
    &--temporary {
      box-shadow: none;
    }

    // Adjust margins/padding to avoid layout shifts
    // when expanding/collapsing the navigation drawer
    .v-list-item {
      padding: 0px 12px;

      &--active::before {
        opacity: 0;
      }

      &__icon {
        margin-top: 12px;
        margin-bottom: 12px;

        &:first-child {
          margin-right: 16px;
        }
      }
    }
    &--mini-variant {
      .v-list-item > *:first-child {
        margin-right: 0px;
      }
    }

    // Safety precaution for browsers that
    // might try to display a scrollbar despite
    // our best efforts to hide it - prevents the
    // list item from shrinking past a certain point
    .v-list-item, .v-list-group {
      min-width: 48px;
    }

    // Increase spacing between each item in the list
    .v-list--nav .v-list-item:not(:last-child):not(:only-child),
    .v-list--nav .v-list-group:not(:last-child):not(:only-child) {
      margin-bottom: 16px;
    }

    // Set nav link colours based on the active route
    .v-list-item--route-active {
      &.v-list-item--link, &>.v-list-group__header {
        background-color: rgba(colors.$primary, 0.15) !important;
        border-color: rgba(colors.$primary, 0.15) !important;
      }
    }

    // Manage button border radius inside nav list
    .v-list--nav .v-list-item,.v-list--nav .v-list-item:before {
      border-radius: 16px
    }

    // Manage button border radius inside nav list
    .v-item-group.v-bottom-navigation, .v-list-item {
      .v-btn {
        border-radius: 16px !important;
      }
    }

    // Change nested list item indentation
    .v-list-group--no-action > .v-list-group__items > .v-list-item {
      margin-left: 56px;
      padding-left: 28px;
    }
  }

  &__content {
    height: 100%;
    overflow: hidden;
  }

  &__toggle {
    top: 20px;
    right: -12px;
    position: absolute;
    z-index: 1;
  }

  &__logo > ::v-deep svg {
    width: 100%
  }
}
</style>
