// Plans
export const BASIC_PLAN = 'Basic-Plan';
export const SMART_PLAN = 'Smart-Plan';
export const SMART_PLAN_PLUS = 'Managed-Plan';
export const MARKET_INTELLIGENCE_PLAN = 'Market-Intelligence-Plan';
export const COMMERCIAL_BASIC_PLAN = 'Commercial-Basic';
export const COMMERCIAL_PLAN = 'Commercial';
export const ADVISOR_PLAN = 'Advisor';
export const TELUS_BASIC_PLAN = 'Telus-Basic';
export const TELUS_MARKET_INTELLIGENCE_PLAN = 'Telus-Market-Intelligence';
export const TELUS_SMART_PLAN = 'Telus-Smart-Plan';
export const TELUS_SMART_PLAN_PLUS = 'Telus-Smart-Plus-Plan';
export const SYNGENTA_BASIC_EMO = 'Syngenta-Basic-EMO';
export const SYNGENTA_BASIC_MAS = 'Syngenta-Basic-MAS';
export const SYNGENTA_BASIC_GMP = 'Syngenta-Basic-GMP';
export const GARS_GMP = 'GARS_GMP';
export const JEREMYANDRES_GMP = 'JeremyAndres_GMP';
export const KEATINGSEEDS_GMP = 'KeatingSeeds_GMP';
export const NADEAU_GMP = 'Nadeau_GMP';
export const FOUR_PECKS_GMP = '4Pecks_GMP';
export const US_BASIC_PLAN = 'US-Basic';
export const US_MARKET_INTELLIGENCE_PLAN = 'US-Market-Intelligence';
export const US_COMMERCIAL_BASIC_PLAN = 'US-Commercial-Basic';
export const US_COMMERCIAL_PLAN = 'US-Commercial';
export const STARTER_PLAN = 'Starter-Plan';
export const COMMERCIAL_STARTER_PLAN = 'Commercial-Starter-Plan';
export const LEGACY_AGRIPARTNERS_AG_NEWS = 'Legacy-AgriPartners-Ag-News';
export const CFFO_BASIC_EMO = 'CFFO_BASIC_EMO';

export const PLANS = Object.freeze([
  BASIC_PLAN,
  MARKET_INTELLIGENCE_PLAN,
  SMART_PLAN,
  SMART_PLAN_PLUS,
  COMMERCIAL_BASIC_PLAN,
  COMMERCIAL_PLAN,
  ADVISOR_PLAN,
  TELUS_BASIC_PLAN,
  TELUS_MARKET_INTELLIGENCE_PLAN,
  TELUS_SMART_PLAN,
  TELUS_SMART_PLAN_PLUS,
  SYNGENTA_BASIC_EMO,
  SYNGENTA_BASIC_MAS,
  SYNGENTA_BASIC_GMP,
  GARS_GMP,
  JEREMYANDRES_GMP,
  KEATINGSEEDS_GMP,
  NADEAU_GMP,
  FOUR_PECKS_GMP,
  US_BASIC_PLAN,
  US_MARKET_INTELLIGENCE_PLAN,
  US_COMMERCIAL_BASIC_PLAN,
  US_COMMERCIAL_PLAN,
  STARTER_PLAN,
  COMMERCIAL_STARTER_PLAN,
  LEGACY_AGRIPARTNERS_AG_NEWS,
  CFFO_BASIC_EMO,
] as const);
export type Plan = typeof PLANS[number];

export const PRODUCER_PLANS = Object.freeze([
  BASIC_PLAN,
  MARKET_INTELLIGENCE_PLAN,
  SMART_PLAN,
  SMART_PLAN_PLUS,
] as const);
export type ProducerPlan = typeof PRODUCER_PLANS[number];

export const COMMERCIAL_PLANS = Object.freeze([
  COMMERCIAL_BASIC_PLAN,
  COMMERCIAL_PLAN,
] as const);
export type CommercialPlan = typeof COMMERCIAL_PLANS[number];

export const EXTRA_PLANS = Object.freeze([
  ADVISOR_PLAN,
] as const);
export type ExtraPlan = typeof EXTRA_PLANS[number];

export const TELUS_PLANS = Object.freeze([
  TELUS_BASIC_PLAN,
  TELUS_MARKET_INTELLIGENCE_PLAN,
  TELUS_SMART_PLAN,
  TELUS_SMART_PLAN_PLUS,
] as const);
export type TelusPlan = typeof TELUS_PLANS[number];

export const SYNGENTA_PLANS = Object.freeze([
  SYNGENTA_BASIC_EMO,
  SYNGENTA_BASIC_MAS,
  SYNGENTA_BASIC_GMP,
] as const);
export type SyngentaPlan = typeof SYNGENTA_PLANS[number];

// Addons
export const DEPUTTER_ADDON = 'DePutter-Publications';
export const FARMLINK_ADDON = 'FarmLink-Publications';
export const KOSTAL_ADDON = 'Kostal-Ag-Publications';
export const WEEKLY_RESEARCH_ADDON = 'Weekly-Research';
export const DAILY_MARKET_WIRE_ADDON = 'Daily-Market-Wire';
export const CROP_UPDATE_ADDON = 'Crop-Update';
export const MARKET_UPDATE_ADDON = 'Market-Update';
export const SALES_RECOMMENDATIONS_ADDON = 'Sales-Recommendations';
export const BEYOND_THE_FIELD_ADDON = 'Beyond-the-Field';
export const AG_ALERT_ADDON = 'Ag-Alert';
export const GROWING_TRENDS_ADDON = 'Growing-Trends';
export const E_MORNING_ONTARIO_ADDON = 'E-Morning-Ontario';
export const GOOD_MORNING_PRAIRIES_ADDON = 'Good-Morning-Prairies';
export const INTEREST_RATE_ALERT_ADDON = 'Interest-Rate-Alert';
export const MARKET_ADVISORY_SERVICES_ADDON = 'Market-Advisory-Services';
export const SPECIAL_REPORT_ADDON = 'Special-Report';
export const DEPUTTER_SPECIAL_REPORT_ADDON = 'DePutter-Special-Report';
export const AGRESOURCE_DAILY_ADDON = 'AgResource-Daily';
export const AGRESOURCE_WEEKLY_ADDON = 'AgResource-Weekly';
export const AGRESOURCE_PLUS_ADDON = 'AgResource-Plus';
export const AGRESOURCE_BUNDLE_ADDON = 'AgResource-Bundle';
export const AG_NEWS_ROUNDUP_ADDON = 'Ag-News-Roundup';

export const ADDONS = Object.freeze([
  DEPUTTER_ADDON,
  FARMLINK_ADDON,
  KOSTAL_ADDON,
  WEEKLY_RESEARCH_ADDON,
  DAILY_MARKET_WIRE_ADDON,
  CROP_UPDATE_ADDON,
  MARKET_UPDATE_ADDON,
  SALES_RECOMMENDATIONS_ADDON,
  BEYOND_THE_FIELD_ADDON,
  AG_ALERT_ADDON,
  GROWING_TRENDS_ADDON,
  E_MORNING_ONTARIO_ADDON,
  GOOD_MORNING_PRAIRIES_ADDON,
  INTEREST_RATE_ALERT_ADDON,
  MARKET_ADVISORY_SERVICES_ADDON,
  SPECIAL_REPORT_ADDON,
  DEPUTTER_SPECIAL_REPORT_ADDON,
  AGRESOURCE_DAILY_ADDON,
  AGRESOURCE_WEEKLY_ADDON,
  AGRESOURCE_PLUS_ADDON,
  AGRESOURCE_BUNDLE_ADDON,
  AG_NEWS_ROUNDUP_ADDON,
] as const);
export type Addon = typeof ADDONS[number];

export const GROUP_ADDONS = Object.freeze([
  DEPUTTER_ADDON,
  FARMLINK_ADDON,
  KOSTAL_ADDON,
  WEEKLY_RESEARCH_ADDON,
  AGRESOURCE_BUNDLE_ADDON,
] as const);
export type GroupAddon = typeof GROUP_ADDONS[number];

export const INDIVIDUAL_CONTENT_ADDONS = Object.freeze([
  DAILY_MARKET_WIRE_ADDON,
  CROP_UPDATE_ADDON,
  MARKET_UPDATE_ADDON,
  SALES_RECOMMENDATIONS_ADDON,
  BEYOND_THE_FIELD_ADDON,
  AG_ALERT_ADDON,
  GROWING_TRENDS_ADDON,
  E_MORNING_ONTARIO_ADDON,
  GOOD_MORNING_PRAIRIES_ADDON,
  INTEREST_RATE_ALERT_ADDON,
  MARKET_ADVISORY_SERVICES_ADDON,
  SPECIAL_REPORT_ADDON,
  DEPUTTER_SPECIAL_REPORT_ADDON,
  AGRESOURCE_DAILY_ADDON,
  AGRESOURCE_WEEKLY_ADDON,
  AGRESOURCE_PLUS_ADDON,
  AG_NEWS_ROUNDUP_ADDON,
] as const);
export type IndividualContentAddon = typeof INDIVIDUAL_CONTENT_ADDONS[number];

// Friendly names
export const FRIENDLY_ITEM_NAMES = Object.freeze({
  [BASIC_PLAN]: 'Basic Plan',
  [SMART_PLAN]: 'Smart Plan',
  [MARKET_INTELLIGENCE_PLAN]: 'Market Intelligence',
  [SMART_PLAN_PLUS]: 'Smart Plan+',
  [COMMERCIAL_BASIC_PLAN]: 'Commercial Basic',
  [COMMERCIAL_PLAN]: 'Commercial',
  [DEPUTTER_ADDON]: 'DePutter Publications',
  [FARMLINK_ADDON]: 'FarmLink Publications',
  [KOSTAL_ADDON]: 'Kostal Ag Publications',
  [ADVISOR_PLAN]: 'Advisor',
  [TELUS_BASIC_PLAN]: 'Basic Plan',
  [TELUS_MARKET_INTELLIGENCE_PLAN]: 'Market Intelligence',
  [TELUS_SMART_PLAN]: 'Smart Plan',
  [TELUS_SMART_PLAN_PLUS]: 'Smart Plan+',
  [SYNGENTA_BASIC_EMO]: 'Syngenta Basic - EMO',
  [SYNGENTA_BASIC_MAS]: 'Syngenta Basic - MAS',
  [SYNGENTA_BASIC_GMP]: 'Syngenta Basic - GMP',
  [GARS_GMP]: 'GARS GMP',
  [JEREMYANDRES_GMP]: 'Andres Stock & Seed GMP',
  [KEATINGSEEDS_GMP]: 'KeatingSeeds GMP',
  [NADEAU_GMP]: 'Nadeau GMP',
  [FOUR_PECKS_GMP]: '4 Pecks GMP',
  [US_BASIC_PLAN]: 'Basic Plan',
  [US_MARKET_INTELLIGENCE_PLAN]: 'Market Intelligence',
  [US_COMMERCIAL_BASIC_PLAN]: 'Commercial Basic',
  [US_COMMERCIAL_PLAN]: 'Commercial',
  [WEEKLY_RESEARCH_ADDON]: 'Weekly Research',
  [DAILY_MARKET_WIRE_ADDON]: 'Daily Market Wire',
  [CROP_UPDATE_ADDON]: 'Crop Update',
  [MARKET_UPDATE_ADDON]: 'Market Update',
  [SALES_RECOMMENDATIONS_ADDON]: 'Sales Recommendations',
  [BEYOND_THE_FIELD_ADDON]: 'Beyond the Field',
  [AG_ALERT_ADDON]: 'Ag-Alert',
  [GROWING_TRENDS_ADDON]: 'Growing Trends',
  [E_MORNING_ONTARIO_ADDON]: 'E-Morning Ontario',
  [GOOD_MORNING_PRAIRIES_ADDON]: 'Good Morning Prairies',
  [INTEREST_RATE_ALERT_ADDON]: 'Interest Rate Alert',
  [MARKET_ADVISORY_SERVICES_ADDON]: 'Market Advisory Services',
  [SPECIAL_REPORT_ADDON]: 'Special Report',
  [DEPUTTER_SPECIAL_REPORT_ADDON]: 'DePutter Special Report',
  [AGRESOURCE_DAILY_ADDON]: 'AgResource Daily',
  [AGRESOURCE_WEEKLY_ADDON]: 'AgResource Weekly',
  [AGRESOURCE_PLUS_ADDON]: 'AgResource Plus',
  [AGRESOURCE_BUNDLE_ADDON]: 'AgResource Bundle',
  [AG_NEWS_ROUNDUP_ADDON]: 'Ag News Roundup',
  [STARTER_PLAN]: 'Starter Plan',
  [COMMERCIAL_STARTER_PLAN]: 'Commercial Starter Plan',
  [LEGACY_AGRIPARTNERS_AG_NEWS]: 'Legacy AgriPartners Ag News',
  [CFFO_BASIC_EMO]: 'CFFO Basic - EMO',
} as const);

// Currencies
export const USD = 'USD';
export const CAD = 'CAD';
export const CURRENCIES = Object.freeze([CAD, USD] as const);
export type Currency = typeof CURRENCIES[number];

// Frequencies
export const MONTHLY = 'Monthly';
export const ANNUAL = 'Yearly';
export const QUARTERLY = 'Quarterly';
export const SEMIANNUAL = 'Semi-Annually';
export const FREQUENCIES = Object.freeze([MONTHLY, ANNUAL, QUARTERLY, SEMIANNUAL] as const);
export type Frequency = typeof FREQUENCIES[number];

export const FRIENDLY_FREQUENCIES_NAMES = Object.freeze({
  [MONTHLY]: 'Monthly',
  [ANNUAL]: 'Annually',
  [QUARTERLY]: 'Quarterly',
  [SEMIANNUAL]: 'Semiannually',
} as const);

// Portal Sections - Standalone
export const SUBSCRIPTION_DETAILS = 'sub_details';
export const ACCOUNT_DETAILS = 'account_details';
export const ADDRESS = 'portal_address';
export const PAYMENT_SOURCES = 'portal_payment_methods';
export const BILLING_HISTORY = 'portal_billing_history';
export const SECTIONS_STANDALONE = Object.freeze([
  SUBSCRIPTION_DETAILS,
  ACCOUNT_DETAILS,
  ADDRESS,
  PAYMENT_SOURCES,
  BILLING_HISTORY,
] as const);
export type StandaloneSection = typeof SECTIONS_STANDALONE[number];

// Portal Sections - Forwarding
export const SUBSCRIPTION_CANCELLATION = 'sub_cancel';
export const EDIT_SUBSCRIPTION = 'edit_subscription';
export const VIEW_SCHEDULED_CHANGES = 'scheduled_changes';
export const EDIT_ACCOUNT_DETAILS = 'portal_edit_account';
export const EDIT_BILLING_ADDRESS = 'portal_edit_billing_address';
export const EDIT_SHIPPING_ADDRESS = 'portal_edit_shipping_address';
export const EDIT_SUBSCRIPTION_CUSTOM_FIELDS = 'portal_edit_subscription_cf';
export const ADD_PAYMENT_SOURCE = 'portal_add_payment_method';
export const EDIT_PAYMENT_SOURCE = 'portal_edit_payment_method';
export const VIEW_PAYMENT_SOURCE = 'portal_view_payment_method';
export const CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION = 'portal_choose_payment_method';
export const SECTIONS_FORWARDING = Object.freeze([
  SUBSCRIPTION_CANCELLATION,
  EDIT_SUBSCRIPTION,
  VIEW_SCHEDULED_CHANGES,
  EDIT_ACCOUNT_DETAILS,
  EDIT_BILLING_ADDRESS,
  EDIT_SHIPPING_ADDRESS,
  EDIT_SUBSCRIPTION_CUSTOM_FIELDS,
  ADD_PAYMENT_SOURCE,
  EDIT_PAYMENT_SOURCE,
  VIEW_PAYMENT_SOURCE,
  CHOOSE_PAYMENT_METHOD_FOR_SUBSCRIPTION,
] as const);
export type ForwardingSection = typeof SECTIONS_FORWARDING[number];

// Credit Cards
export const CC_VISA = 'Visa';
export const CC_MASTERCARD = 'Mastercard';
export const CC_AMERICAN_EXPRESS = 'American Express';
export const CC_DISCOVER = 'Discover';
export const CC_JCB = 'JCB';
export const CC_DINERS_CLUB = 'Diner\'s Club';
export const CC_OTHER = 'Other';
export const CREDIT_CARDS = Object.freeze({
  visa: CC_VISA,
  mastercard: CC_MASTERCARD,
  american_express: CC_AMERICAN_EXPRESS,
  discover: CC_DISCOVER,
  jcb: CC_JCB,
  diners_club: CC_DINERS_CLUB,
  other: CC_OTHER,
} as const);

// Timed Coupon
export const COUPON_COHORTS = Object.freeze([
  BASIC_PLAN,
  MARKET_INTELLIGENCE_PLAN,
  SMART_PLAN,
  GARS_GMP,
  JEREMYANDRES_GMP,
  KEATINGSEEDS_GMP,
  COMMERCIAL_PLAN,
  COMMERCIAL_BASIC_PLAN,
] as const);
