<template>
  <v-dialog
    ref="dialog"
    :value="value"
    :fullscreen="isFullscreenAllowed"
    :retain-focus="false"
    :max-width="width"
    :transition="transition"
    width="100%"
    scrollable
    persistent
    no-click-animation
  >
    <v-form
      ref="form"
      v-model="isFormValid"
      :lazy-validation="lazyValidation"
      :disabled="isProcessingRequest"
      @submit.prevent
    >
      <v-card
        flat
        tile
        :loading="isProcessingRequest || loading"
        :class="flexClass"
        class="g-dialog-card"
      >
        <v-card-title
          data-qa="g-dialog-title"
          class="flex-nowrap"
          :class="{'elevation-3 z--2': fixedButtonsForOverflow && isMobile,
                   'primary py-2': isNewFeaturePopup && isMobile}"
        >
          <template v-if="isMobileFullscreen">
            <v-btn
              v-if="showBackButton"
              data-qa="g-dialog-left-arrow-button"
              icon
              :color="isNewFeaturePopup && isMobile ? 'white' : 'primary'"
              :disabled="isProcessingRequest || loading"
              @click="cancel"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <slot name="title-prepend" />
          <div
            :class=" {'white--text': isNewFeaturePopup && isMobile}"
          >
            {{ title }}
          </div>
          <v-spacer />
          <slot name="title.append" />
          <v-btn
            v-if="!isMobileFullscreen && showCloseButton"
            :disabled="loading"
            class="align-self-start"
            icon
            data-qa="g-dialog-close-button"
            @click="cancel"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle
          v-if="hasSubtitle"
          class="primary-grey--text overflow-hidden d-flex flex-column flex-shrink-0"
          :class="subtitleClasses"
        >
          <span>{{ subtitleTrimmed }}</span>
          <slot name="subtitle.append" />
        </v-card-subtitle>
        <v-card-text
          ref="content"
          :style="maxHeightStyle"
          class="pt-4"
          :class="[{ 'pa-0': contentNoPadding }, textClass]"
        >
          <slot />
          <g-dialog-actions
            v-if="isMobileFullscreen && !fixedButtonsForOverflow"
            :is-mobile="isMobile"
            :is-form-valid="isFormValid || lazyValidation"
            :is-mobile-fullscreen="isMobileFullscreen"
            :is-processing-request="isProcessingRequest || loading"
            :action-buttons="actionButtons"
            @process-click="processClick"
          />
        </v-card-text>
        <g-dialog-actions
          v-if="fixedButtonsForOverflow && isMobileFullscreen"
          :is-mobile="isMobile"
          :is-form-valid="isFormValid || lazyValidation"
          :is-mobile-fullscreen="!isMobileFullscreen"
          :is-processing-request="isProcessingRequest || loading"
          :action-buttons="actionButtons"
          @process-click="processClick"
        />
        <g-dialog-actions
          v-if="!isMobileFullscreen"
          :is-mobile="isMobile"
          :is-form-valid="isFormValid || lazyValidation"
          :is-mobile-fullscreen="isMobileFullscreen"
          :is-processing-request="isProcessingRequest || loading"
          :action-buttons="actionButtons"
          @process-click="processClick"
        />
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import GDialogActions from './GDialogActions.vue';

export default {
  name: 'GDialog',

  components: {
    GDialogActions,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    transition: {
      type: String,
      default: 'dialog-transition',
    },

    subtitle: {
      type: String,
      default: '',
    },

    actionButtons: {
      type: Array,
      default: () => [],
    },

    disableFullscreen: {
      type: Boolean,
      default: false,
    },

    desktopFullscreen: {
      type: Boolean,
      default: false,
    },

    showBackButton: {
      type: Boolean,
      default: true,
    },

    showCloseButton: {
      type: Boolean,
      default: true,
    },

    width: {
      type: [String, Number],
      default: 600,
    },

    height: {
      type: [String, Number],
      default: 500,
    },

    value: {
      type: Boolean,
      required: true,
    },

    mobileBreakpoint: {
      type: String,
      default: 'xs',
    },

    lazyValidation: {
      type: Boolean,
      default: false,
    },

    fixedButtonsForOverflow: {
      type: Boolean,
      default: false,
    },

    isNewFeaturePopup: {
      // Makes header text white with orange background
      type: Boolean,
      default: false,
    },

    keepForm: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    side: {
      type: String,
      default: '',
    },

    contentNoPadding: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isProcessingRequest: false,
      isFormValid: false,
      wrapper: null,
    };
  },

  computed: {
    isOpen() {
      return this.value;
    },

    subtitleTrimmed() {
      return (typeof this.subtitle === 'string') ? this.subtitle.trim() : '';
    },

    hasSubtitle() {
      return this.subtitleTrimmed.length > 0;
    },

    isMobile() {
      return this.$vuetify.breakpoint[this.mobileBreakpoint];
    },

    isMobileFullscreen() {
      return this.isMobile && !this.disableFullscreen;
    },

    isDesktopFullscreen() {
      return this.desktopFullscreen && !this.isMobile;
    },

    isFullscreenAllowed() {
      return this.isMobileFullscreen || this.isDesktopFullscreen;
    },

    maxHeightStyle() {
      return `max-height: ${this.isMobileFullscreen ? 'none' : `${this.height}px`};`;
    },

    subtitleClasses() {
      return {
        'mt-n8': this.isMobileFullscreen && !this.isNewFeaturePopup, // Prevents subtitle from overlapping with title's orange background
        'mt-0': !this.isMobileFullscreen,
        'pl-13': this.isMobileFullscreen,
      };
    },

    flexClass() {
      return this.isMobile ? 'd-flex flex-column' : '';
    },

    textClass() {
      return this.isMobile ? 'd-flex flex-column flex-grow-1' : '';
    },
  },

  watch: {
    isOpen(newVal) {
      if (!newVal && !this.keepForm) {
        this.$refs.form.reset();
        return;
      }

      this.isProcessingRequest = false;

      this.$nextTick(() => {
        this.wrapper = this.$refs.dialog.$refs.content;
        this.$refs.content.scrollTop = 0;
        this.$refs.form.resetValidation();
      });
    },

    isFormValid(newVal) {
      this.$emit('formValidUpdate', newVal);
    },
  },

  methods: {
    cancel() {
      this.$emit('cancel');
      this.$emit('input', false);
    },

    resetForm() {
      if (this.$refs.form) this.$refs.form.reset();
    },

    async processClick({ onClick, mustValidate }) {
      if (mustValidate) {
        this.isFormValid = this.$refs.form.validate();
        if (!this.isFormValid) return;
      }

      this.isProcessingRequest = true;

      try {
        await onClick();
        this.isProcessingRequest = false;
      } catch (e) {
        this.isProcessingRequest = false;
      }
    },

    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
